var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "fantasy-name" },
    [
      _vm.perfilHelpers.checkPermission([
        _vm.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
      ])
        ? _c(
            "div",
            { staticClass: "mt-3 mb-5 flex flex-row-reverse" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.includeNewFantasyName()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "onpoint-plus icon-font pr-3" }),
                  _vm._v(
                    "\n    Incluir  " +
                      _vm._s(
                        _vm.segurado.Entity === "J"
                          ? "Nome Fantasia"
                          : "Nome Social"
                      )
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      (_vm.seguradoFansatyName.list || []).length > 0
        ? _c(
            "div",
            _vm._l(_vm.seguradoFansatyName.list, function(item, index) {
              return _c("vs-card", { key: item.Id }, [
                _c(
                  "div",
                  { staticClass: "flex items-center p-5" },
                  [
                    _c(
                      "div",
                      { staticClass: "ml-2 mr-3 flex flex-col flex-grow" },
                      [
                        _c("p", { staticClass: "text-base font-semibold" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.Name) +
                              "\n          "
                          )
                        ])
                      ]
                    ),
                    _vm.perfilHelpers.checkPermission([
                      _vm.perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_SEGURADOS
                    ])
                      ? _c("vs-button", {
                          attrs: {
                            radius: "",
                            color: "primary",
                            type: "flat",
                            size: "large",
                            icon: "edit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.EditFantasyName(item, index)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.perfilHelpers.checkPermission([
                      _vm.perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_SEGURADOS
                    ]) && item.IsMainName != true
                      ? _c("vs-button", {
                          attrs: {
                            radius: "",
                            color: "primary",
                            type: "flat",
                            size: "large",
                            icon: "delete"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeFantasyName(item, index)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            }),
            1
          )
        : [_vm._m(0)],
      _c(
        "vs-popup",
        {
          staticClass: "nomefantasia",
          attrs: { title: "", active: _vm.popupNomeFantasia },
          on: {
            "update:active": function($event) {
              _vm.popupNomeFantasia = $event
            }
          }
        },
        [
          _c("h3", { staticClass: "pl-4 pb-6" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.fantasyNameSelecionado.Id
                    ? _vm.segurado.Entity === "J"
                      ? "Editar  Nome Fantasia"
                      : " Editar Nome Social"
                    : _vm.segurado.Entity === "J"
                    ? "Adicionar Nome Fantasia"
                    : "Adicionar Nome Social"
                ) +
                "\n    "
            )
          ]),
          _c("div", { staticClass: "p-4 pt-0" }, [
            _vm.fantasyNameSelecionado.Id
              ? _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        name:
                          _vm.segurado.Entity === "J"
                            ? "Nome Fantasia"
                            : "Nome Social",
                        rules: "required"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label:
                                      _vm.segurado.Entity === "J"
                                        ? "Nome Fantasia"
                                        : "Nome Social",
                                    size: "large"
                                  },
                                  model: {
                                    value: _vm.fantasyNameSelecionado.Name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.fantasyNameSelecionado,
                                        "Name",
                                        $$v
                                      )
                                    },
                                    expression: "fantasyNameSelecionado.Name"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0]"
                                      }
                                    ],
                                    staticClass: "text-danger "
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(errors[0]) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 mt-5 flex flex-row-reverse w-full"
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: { disabled: errors[0] },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveEditFantasyName()
                                          }
                                        }
                                      },
                                      [_vm._v("Salvar")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3767787511
                      )
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        name:
                          _vm.segurado.Entity === "J"
                            ? "Nome Fantasia"
                            : "Nome Social",
                        rules: "required"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  label:
                                    _vm.segurado.Entity === "J"
                                      ? "Nome Fantasia"
                                      : "Nome Social",
                                  size: "large"
                                },
                                model: {
                                  value: _vm.novoNomeFantasia,
                                  callback: function($$v) {
                                    _vm.novoNomeFantasia = $$v
                                  },
                                  expression: "novoNomeFantasia"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]"
                                    }
                                  ],
                                  staticClass: "text-danger "
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(errors[0]) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 mt-5 flex flex-row-reverse w-full"
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { disabled: errors[0] },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveFantasyName()
                                        }
                                      }
                                    },
                                    [_vm._v("Salvar")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32"
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description")
          ])
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Tomador ainda não possui nenhum nome fantasia cadastrado.\n        "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }