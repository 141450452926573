var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "proposal-documents" },
    [
      _vm.profileHelpers.checkPermission([
        _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
      ])
        ? _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col pt-5 text-right w-full mt-2" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.openDialogUpload()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n          Upload de documento\n        ")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "vs-table",
        {
          staticClass: "mt-10 table-available",
          attrs: {
            data: _vm.validDocuments,
            noDataText: "Nenhum arquivo foi cadastrado"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("\n        Nome\n      ")]),
              _c("vs-th", [_vm._v("\n        Data de inserção\n      ")]),
              _c("vs-th", [_vm._v("\n        Ações\n      ")])
            ],
            1
          ),
          _vm._l(_vm.validDocuments, function(document, indextr) {
            return _c(
              "vs-tr",
              { key: indextr, attrs: { data: document } },
              [
                _c("vs-td", [_vm._v(_vm._s(_vm.validDocuments[indextr].Name))]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.validDocuments[indextr].CreateDate,
                        "DD/MM/YYYY HH:mm"
                      )
                    )
                  )
                ]),
                _c(
                  "vs-td",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          disabled: !_vm.validDocuments[indextr].TotalArchives,
                          color: "dark",
                          type: "flat",
                          title: "Download do documento"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.downloadDocument(
                              _vm.validDocuments[indextr].UniqueId
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "onpoint-download-simple icon-font"
                        })
                      ]
                    ),
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_SEGURADOS
                    ])
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "p-0",
                            attrs: {
                              color: "dark",
                              type: "flat",
                              title: "Editar nome do documento"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.openDialogEditDocument(
                                  _vm.validDocuments[indextr].UniqueId
                                )
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "onpoint-pencil-simple-line icon-font"
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_SEGURADOS
                    ])
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "p-0",
                            attrs: {
                              color: "dark",
                              type: "flat",
                              title: "Excluir documento"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.openDialogDeleteDocument(
                                  _vm.validDocuments[indextr].UniqueId,
                                  _vm.validDocuments[indextr].Name
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "onpoint-trash icon-font" })]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.validDocuments.length > 0,
              expression: "validDocuments.length > 0"
            }
          ],
          staticClass: "download-all",
          on: {
            click: function($event) {
              return _vm.downloadAllDocuments()
            }
          }
        },
        [
          _c("span", [_vm._v("Baixar todos")]),
          _c("i", { staticClass: "onpoint-download icon-font" })
        ]
      ),
      _c("h4", [_vm._v("Documentos excluidos")]),
      _c(
        "vs-table",
        {
          staticClass: "mt-10 table-trash",
          attrs: {
            data: _vm.trashDocuments,
            noDataText: "Nenhum arquivo foi excluído"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("\n        Nome\n      ")]),
              _c("vs-th", [_vm._v("\n        Data de inserção\n      ")]),
              _c("vs-th", [_vm._v("\n        Data da exclusão\n      ")]),
              _c("vs-th", [_vm._v("\n        Ações\n      ")])
            ],
            1
          ),
          _vm._l(_vm.trashDocuments, function(document, indextr) {
            return _c(
              "vs-tr",
              { key: indextr, attrs: { data: document } },
              [
                _c("vs-td", [_vm._v(_vm._s(_vm.trashDocuments[indextr].Name))]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm.trashDocuments[indextr].CreateDate
                        ? _vm.$utils.dateToDDMMYYYY(
                            _vm.trashDocuments[indextr].CreateDate
                          )
                        : "-"
                    )
                  )
                ]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.trashDocuments[indextr].CreateDate,
                        "DD/MM/YYYY HH:mm"
                      )
                    )
                  )
                ]),
                _c("vs-td", [
                  _c(
                    "p",
                    {
                      staticClass: "restore",
                      on: {
                        click: function($event) {
                          return _vm.restoreDocument(
                            _vm.trashDocuments[indextr].UniqueId
                          )
                        }
                      }
                    },
                    [_vm._v("\n            Restaurar\n          ")]
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      ),
      _vm.openDialogAddDocumente
        ? _c("base64Upload", {
            attrs: {
              modalUpload: _vm.openDialogAddDocumente,
              documentoName: "",
              insertName: true
            },
            on: {
              callBackUpload: _vm.callBackUpload,
              closeModal: _vm.closeModal
            }
          })
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "popup-edit-documents z-index-modal",
          attrs: { title: "Editar nome", active: _vm.openEditDocument },
          on: {
            "update:active": function($event) {
              _vm.openEditDocument = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("p", { staticClass: "flex font-semibold" }, [
                  _vm._v("Informe o novo nome")
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large" },
                  model: {
                    value: _vm.newDocumentName,
                    callback: function($$v) {
                      _vm.newDocumentName = $$v
                    },
                    expression: "newDocumentName"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "vx-row flex justify-end" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.editDocument()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          ")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-delete-documents z-index-modal",
          attrs: { title: "Deletar arquivo", active: _vm.openDeleteDocument },
          on: {
            "update:active": function($event) {
              _vm.openDeleteDocument = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", [
              _c("h4", [_vm._v("Confirma a ação de deleter o documento?")])
            ])
          ]),
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", { staticClass: "document-name" }, [
              _vm._v(_vm._s(_vm.documentName))
            ])
          ]),
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-10",
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function($event) {
                        _vm.openDeleteDocument = false
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Voltar\n          ")
                    ])
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.deleteDocument(_vm.RequestDocumentUniqueId)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          ")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }